<template>
  <div class="content">
    <div class="header-com">
      <NavBar :title="'game'" :backColor="'transparent'"></NavBar>
      <div class="header-money-com">
        <span>tổng cộng</span>
        <p>{{ money }}</p>
      </div>
      <div class="header-content">
        <p @click="handleLink('out')">Chuyển về tài khoản</p>
        <p @click="handleLink('income')">Chuyển vào trò chơi</p>
      </div>
    </div>
    <div class="balance-com">
      <div class="balance-title">hồ sơ số dư</div>
      <div class="balance-lists">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="không còn nữa"
          loading-text="Đang tải..."
          @load="onLoad"
        >
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <div class="item-title">
              <span>{{ item.title }}</span>
              <p v-if="item.setup <= 0" class="cell-top-amount-green">
                -{{ item.setup }}
              </p>
              <p v-else class="cell-top-amount">{{ item.setup }}</p>
            </div>
            <div class="item-info">
              <span> {{ dataTransition(item.indate) }} </span>
              <p>{{ item.subtype }}</p>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar'
import request from '@/utils/request'
import { formatDate } from '@/utils/unique'
export default {
  name: '',
  components: { NavBar },
  data() {
    return {
      money: '0.00',
      list: [],
      loading: false,
      finished: false,
      page: this.pages,
      total: 0,
    }
  },
  mounted() {},
  created() {
    this.getUserBalance()
  },
  methods: {
    /* HANDLE */
    handleLink(type) {
      this.$router.push({
        path: '/game-money-info',
        query: { type },
      })
    },
    /* API */
    async getUserBalance() {
      const {
        data: { data, ret },
      } = await request({
        method: 'POST',
        url: 'obgame/qm/balance',
      })
      if (ret === 1) {
        if (data.bal) {
          this.money = (data.bal * 1).toFixed(2)
        } else {
          this.money = '0.00'
        }
      } else {
        this.money = '0.00'
      }
    },
    async getGameRecordList() {
      const {
        data: { data, ret },
      } = await request({
        method: 'POST',
        url: 'users/account/lists',
        data: {
          type: 'money',
          subtype: '10',
          pages: this.pages,
        },
      })
      this.loading = false
      if (ret === 1) {
        this.list = data.lists
        this.total = data.total
        const dataLists = data.lists
        if (data.currentPage > 1) {
          for (let i = 0; i < dataLists.length; i++) {
            this.list.push(dataLists[i])
          }
        } else {
          this.list = dataLists
        }
      }
      if (this.list.length === this.total) {
        this.finished = true
      }
    },
    onLoad() {
      this.getGameRecordList()
    },
    /* 其他 */
    dataTransition(str) {
      if (str) {
        return formatDate('Y-m-d H:i:s', str)
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped lang="less" >
.content {
  /deep/ .van-nav-bar__content {
    background: transparent;
    .iconfont {
      font-size: 44px;
      color: #fff;
    }
  }
  > .header-com {
    width: 100%;
    height: 600px;
    background: url('../../../assets/images/new-bg1.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    > .header-money-com {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fff;
      > span {
        font-size: 60px;
      }
      > p {
        font-size: 96px;
        margin-top: 20px;
      }
    }
    > .header-content {
      width: 680px;
      height: 200px;
      background-color: #fff;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: center;
      padding-top: 40px;
      box-sizing: border-box;
      > p {
        font-size: 30px;
        width: 280px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fb6a25;
        color: #ffffff;
        border-radius: 15px;
      }
      > p:nth-child(1) {
        margin-right: 30px;
        background-color: #fedfd0;
        color: #ed4e03;
      }
    }
  }
  > .balance-com {
    background-color: #fff;
    > .balance-title {
      width: 680px;
      height: 80px;
      margin: auto;
      display: flex;
      align-items: center;
      font-size: 36px;
      color: #999;
    }
    > .balance-lists {
      height: calc(100vh - 600px);
      overflow: auto;
      .list-item {
        width: 100%;
        padding: 35px 35px;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(247, 247, 247);
        > .item-title {
          font-size: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > span {
            color: #000;
          }
          > .cell-top-amount {
            color: #dc1323;
          }
          > .cell-top-amount-green {
            color: #1bb019;
          }
        }
        > .item-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 19px;
          font-size: 24px;
          > span {
            color: #999999;
          }
          > p {
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
